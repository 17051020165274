import React, { useState } from "react";

import { MODEL } from "../components/common/consts";
import Home from "./Home";
import SignUpModal from "../components/common/SignUpModal";

export default function ResetModel() {
  const [openModel, setOpenModal] = useState(true);

  return (
    <>
      <SignUpModal loginFor={MODEL} resetPas isOpen={openModel} setOpen={setOpenModal} />
      <Home />
    </>
  );
}
