import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";
import imgEmail from "../../images/icons/mail.svg";
import { API_LOGIN, serverType } from "../../api/login";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA, SITE_KEY } from "./consts";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPas({ type, close }) {
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);
  const emailRef = useRef(null);
  const captchaRef = useRef(null);
  const LangToHl = { english: "EN", russian: "RU" };
  const HL = LangToHl[t("currentLang")] || "EN";

  const onResetPas = async (e) => {
    console.log(type);
    console.log(serverType[type]);
    window.history.pushState("", "", "/");

    e.preventDefault();
    console.log(emailRef.current);
    setBtnLoading(true);
    API_LOGIN.password
      .reset(
        {
          email: emailRef.current,
          type: serverType[type],
          captcha: captchaRef.current?.getValue(),
        },
        type
      )
      .then((e) => {
        if (!e.data) {
          toast(e.data.message || "Unexpected data received from the server", {
            style: { background: "#008000", color: "#fff" },
          });
        }

        if (e.data.status || e.data.ok) {
          toast(e.data.message || t("your email has been successfully confirmed"), {
            style: { background: "#008000", color: "#fff" },
          });
          return;
        }

        toast(e.data.message || t("An unknown error was received"), {
          style: { background: "#f00", color: "#fff" },
        });

        console.log(e);
      })
      .catch((e) => {
        if (!e.response) {
          toast(t("An unknown error was received"), {
            style: { background: "#f00", color: "#fff" },
          });
        }

        toast(e.data.message || t("An unknown error was received"), {
          style: { background: "#f00", color: "#fff" },
        });
      })
      .finally(() => {
        setBtnLoading(false);
        captchaRef.current?.reset();
        close();
      });
  };

  return (
    <>
      <div className="reset-password">
        <h1 className="reset-password__title">{t("Password Recovery")}</h1>
        <form onSubmit={onResetPas} className="reset-password__content">
          <CusInput
            onChange={(e) => (emailRef.current = e.target.value)}
            required
            icon={imgEmail}
            text={"Type your email"}
          />

          {!!CAPTCHA?.["protect-reset-password-page"] && (
            <ReCAPTCHA ref={captchaRef} hl={HL} sitekey={SITE_KEY} />
          )}

          <CusButton
            type="submit"
            loading={btnLoading}
            className="cus-button__outline"
            text={"Get code"}
          />
          <p>
            {t(
              "After clicking on the 'Get code' button, an email with a confirmation code will be sent to the entered E-mail. If the letter did not come, check the 'SPAM' folder."
            )}
          </p>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
