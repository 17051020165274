import React, { useState } from "react";
import imgMail from "../../images/icons/mail.svg";
import imgTg from "../../images/logo/logos_telegram.svg";
import ApproveModal from "./ApproveModal";
import { useTranslation } from "react-i18next";
import Cookie from "./cookie/Cookie";
import Policy from "./policy/Policy";
import P18usc2257 from "./P18usc2257";
import Terms from "./terms/Terms";
import Dmca from "./dmca/Dmca";

export default function HomeFooter() {
  const approveTexts = {
    service: { title: "Terms of service", text: <Terms /> },
    privacy: { title: "Privacy polices", text: <Policy /> },
    cookies: { title: "Cookies policy", text: <Cookie /> },
    P18usc2257: { title: "18 U.S.C 2257", text: <P18usc2257 /> },
    dmca: { title: "DMCA / Remove Content", text: <Dmca /> },
  };
  const { t } = useTranslation();

  const [openTypeApprove, setOpenTypeApprove] = useState(false);

  const titleModal = approveTexts[openTypeApprove]?.title;
  const textModal = approveTexts[openTypeApprove]?.text;

  return (
    <>
      <div className="home__footer">
        <div className="home__footer__content">
          <div className="home__footer__center">
            <div className="home__footer__actions">
              <button onClick={() => setOpenTypeApprove("service")}>{t("Terms of service")}</button>
              <button onClick={() => setOpenTypeApprove("privacy")}>{t("Privacy polices")}</button>
              <button onClick={() => setOpenTypeApprove("cookies")}>{t("Cookies policy")}</button>
            </div>
            <div className="home__footer__actions__two">
              <span className="home__footer__copyright">
                {" "}
                {t(`©${new Date().getFullYear()} RichCams.com`)}
              </span>
              <button onClick={() => setOpenTypeApprove("dmca")}>
                {t("DMCA / Remove Content")}
              </button>
              <button onClick={() => setOpenTypeApprove("P18usc2257")}>
                {t("18 U. S. C. 2257")}
              </button>
            </div>
          </div>
          <div className="home__footer__right">
            <span>{t("For any questions write to:")}</span>
            <div className="home__footer__connection">
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://t.me/RichCams_Bot"}
                className="action"
              >
                <img alt="tg" src={imgTg} /> @RichCams_Bot
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:streamer@richcams.com"
                className="action"
              >
                <img alt="mail" src={imgMail} /> streamer@richcams.com
              </a>
            </div>
          </div>
        </div>
      </div>

      <ApproveModal
        setOpen={setOpenTypeApprove}
        isOpen={!!openTypeApprove}
        title={titleModal}
        text={textModal}
        type={openTypeApprove}
      />
    </>
  );
}
