export const SIGN_UP = "sign-up";
export const LOGIN = "login";
export const MODEL = "Model";
export const STUDIO = "Studio";

///

export const URL_CONFIRM_MODEL = "/broadcaster/email/confirm";
export const URL_NEW_PAS_MODEL = "/broadcaster/password/reset";
export const URL_CONFIRM_STUDIO = "/manager/email/confirm";
export const URL_NEW_PAS_STUDIO = "/manager/password/reset";
export const URL_CHANGE_PAS = "/user/email/change";

// export const IS_CONFIRM = window.startConfig?.user?.email_confirmed;
export const IS_CONFIRM = window.startConfig?.user?.email;
export const CAPTCHA = window.startConfig?.settings?.captcha;

export const SITE_KEY = CAPTCHA?.["site-key"];
