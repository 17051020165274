import React from "react";
import { useTranslation } from "react-i18next";

export default function Income() {
  const { t } = useTranslation();

  // incomeItems - массив блоков дохода для отображения с типом
  // [
  //   {
  //     type - чей блок дохода, модели или студии
  //     income - размер дохода
  //   }
  // ]
  const incomeItems = [
    { type: "model", income: "$10 071" },
    { type: "studio", income: "$113 297" },
  ];

  return (
    <div className="home__income">
      <div className="home__income__content">
        {/* отображаем массив блоков дохода */}
        {incomeItems.map((el, indx) => (
          <div key={indx} className={`home__income__card  home__income__${el.type}`}>
            <h3 className="home__income__card__title">
              {t("The")}
              <span className="home__income__card__title__accent">{t(el.type)}</span>{" "}
              {t("(avg.) earns on")}{" "}
              <a href="https://richCams.com" target="_blank" rel="noreferrer">
                {t("RichCams.com")}
              </a>
            </h3>

            <span className="home__income__card__value">{el.income}</span>
            <span className="home__income__card__remark">{t("per last week")}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
