import { useEffect } from "react";
import { getCHPYPapamByName } from "../utils/utils";
import i18n from "../i18n";

export default function useCommonHooks() {
  useEffect(() => {
    const referrerUserId = getCHPYPapamByName("r_id");
    // const referrerUserId = getCHPYPapamByName("referrer_user_id");
    const referrerGroupId = getCHPYPapamByName("referrer_group_id");

    localStorage.setItem("campaign_id", getCHPYPapamByName("campaign_id"));

    // if`s
    if (referrerUserId) {
      document.cookie =
        encodeURIComponent("referrer_user_id") + "=" + encodeURIComponent(referrerUserId) + ";";
    }
    if (referrerGroupId) {
      document.cookie =
        encodeURIComponent("referrer_group_id") + "=" + encodeURIComponent(referrerGroupId) + ";";
    }
  }, []);

  useEffect(() => {
    const languages = [
      { title: "English", flag: "us", code: "en", country: "US" },
      { title: "Русский", flag: "ru", code: "ru", country: "RU" },
    ];
    const paramLang = getCHPYPapamByName("lang");

    if (paramLang && languages.length) {
      const currentLang = languages.find((el) => {
        return `${el.code}_${el.country}` === paramLang;
      });

      if (currentLang) {
        const code = currentLang.code;
        localStorage.setItem("lang", code);
        i18n.changeLanguage(code);
      }
    }
  }, []);
}
