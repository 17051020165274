import axios from "axios";
import { API_URL_MODEL, API_URL_STUDIO } from "./main";
import { MODEL } from "../components/common/consts";

export const serverType = {
  Model: "broadcaster",
  Studio: "manager",
};

export const login = async (data, type) => {
  const URL = type === "Model" ? `${API_URL_MODEL}/user/login` : `${API_URL_STUDIO}/user/login`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const logout = async (data) => {
  const isManager = window.startConfig?.user?.type === "broadcaster";
  const URL = isManager ? `${API_URL_MODEL}/user/logout` : `${API_URL_STUDIO}/user/logout`;

  let response = await axios({
    method: "GET",
    url: URL,
    data: data,
  });
  return response;
};

export const register = async (data, type) => {
  const URL = type === MODEL ? `${API_URL_MODEL}/user/join` : `${API_URL_STUDIO}/user/join`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const confirm = async (data, type) => {
  const URL =
    type === MODEL ? `${API_URL_MODEL}/user/email/confirm` : `${API_URL_STUDIO}/user/confirm-email`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const changeEmail = async (data, type) => {
  const URL =
    type === MODEL ? `${API_URL_MODEL}/user/email/change` : `${API_URL_STUDIO}/user/change`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const resetPas = async (data, type) => {
  const URL =
    type === MODEL ? `${API_URL_MODEL}/user/password/reset` : `${API_URL_STUDIO}/user/reset`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const newPas = async (data, type) => {
  const URL =
    type === MODEL ? `${API_URL_MODEL}/user/password/set` : `${API_URL_STUDIO}/user/set-password`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const API_LOGIN = {
  login: login,
  logout: logout,
  register: register,
  confirm: confirm,
  changeEmail: changeEmail,
  password: {
    reset: resetPas,
    new: newPas,
  },
};
