import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { API_LOGIN } from "../api/login";
import { MODEL } from "../components/common/consts";
import Home from "./Home";
import Spinner from "../components/mui/Spinner";
import { useTimeout } from "usehooks-ts";

export default function EmailChange() {
  const [query] = useSearchParams();
  const key = query.get("key");
  const email = query.get("email");
  const { t } = useTranslation();
  const [spinner, setSpinner] = useState(true);

  useTimeout(() => setSpinner(false), 10 * 1000);

  useEffect(() => {
    console.log(key, email);

    if (!key || !email) return;

    API_LOGIN.changeEmail({ key, email }, MODEL)
      .catch((e) => {
        toast(e, { style: { background: "#f00", color: "#fff" } });
        setSpinner(false);
      })
      .then((e) => {
        if (!e.data.ok) {
          toast(e.data.message, { style: { background: "#f00", color: "#fff" } });
          setSpinner(false);
          return;
        }
        toast(t("Email confirm change"), {
          style: { background: "#008000", color: "#fff" },
        });
        const studioType = window.startConfig?.user?.type === "manager";
        const url = studioType ? "/webcam/studio/manager" : "/webcam/model/chat";
        window.location.href = url;
      });
  }, [key, email]);

  return (
    <main
      className={`home ${t("currentLang")}`}
      style={{
        display: "flex",
        minHeight: "100vh",
        background: "black",
      }}
    >
      {spinner ? <Spinner style={{ margin: "auto" }} /> : <Home />}

      <ToastContainer />
    </main>
  );
}
