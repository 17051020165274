import React from "react";
import { useTranslation } from "react-i18next";

export default function CusTextArea({
  className = "",
  onChange,
  name,
  text,
  required,
  errorColor,
  dopOptions,
  grayWarning = "",
}) {
  const { t } = useTranslation();

  return (
    <div className={`custom-input ${className}`}>
      <textarea
        onChange={onChange}
        name={name}
        required={required}
        placeholder={t(text)}
        className={`no-resize custom-input__input ${errorColor ? "error-input" : ""}`}
        {...dopOptions}
      />
      {grayWarning && <span className="custom-input__gray-warning">{t(grayWarning)}</span>}
    </div>
  );
}
