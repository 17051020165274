import React from "react";
import imgDocument from "../../images/icons/document.svg";
import imgVideo from "../../images/icons/video-clip.svg";
import imgArrowRight from "../../images/icons/arrow-up-right.svg";
import { useTranslation } from "react-i18next";

export default function UpperFooter() {
  const { t } = useTranslation();

  return (
    <div className="home__upper-footer">
      <div className="home__upper-footer__content">
        <div className="home__upper-footer__offer">
          <span className="home__upper-footer__ask">{t("Do you have traffic?")}</span>
          <a
            href="https://camsoffer.com"
            target="_blank"
            className="home__upper-footer__actions"
            rel="noreferrer"
          >
            <img src={imgDocument} alt="document" />
            {t("Offer for Webmasters")}
            <img src={imgArrowRight} alt="arrow" />
          </a>
        </div>
        <div className="home__upper-footer__site">
          <span className="home__upper-footer__ask">
            {t("Do you want to have fun with our models?")}
          </span>
          <a
            href="https://richCams.com"
            target="_blank"
            className="home__upper-footer__actions"
            rel="noreferrer"
          >
            <img src={imgVideo} alt="document" />
            {t("RichCams.com")}
            <img src={imgArrowRight} alt="arrow" />
          </a>
        </div>
      </div>
    </div>
  );
}
