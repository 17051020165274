import React from "react";

import advantagesGirl from "../../images/advantages/dvantages-girl.png";

import imgDollar from "../../images/advantages/dollar-red.svg";
import imgBlock from "../../images/advantages/block-red.svg";
import imgCopyright from "../../images/advantages/copyright-red.svg";
import imgVideo from "../../images/advantages/video-clip-red.svg";
import imgUser from "../../images/advantages/user-red.svg";
import imgCode from "../../images/advantages/code-red.svg";
import { useTranslation } from "react-i18next";

export default function Advantages() {
  const { t } = useTranslation();

  // advantagesList - массив со всеми преимуществами следующего вида
  // [
  //   {
  // img - картинки ,
  // title - заголовок,
  // description - описание
  //   }
  // ]
  const advantagesList = [
    {
      img: imgDollar,
      title: "Payouts once a week in USD, EUR,RUB or in crypto USDT, BTC, etc",
    },
    {
      img: imgBlock,
      title: "Block any state or country",
      description:
        "Want to be discreet? Have no worries as our advanced geographical blocking system ensure your anonymity",
    },
    {
      img: imgCopyright,
      title: "Your Content is DMCA Protected",
      description: "All of your content will be secured from copyright infringement and piracy",
    },
    {
      img: imgVideo,
      title: "Get from 50% up to 100% for your show. Earn tips on free shows",
      description: "You set the price",
    },
    {
      img: imgUser,
      title: "You get a personal manager",
      description: "The manager will help you with financial and technical issues",
    },
    {
      img: imgCode,
      title: "You will get access to API",
      description: "You can receive data on your profile in JSON format",
    },
  ];

  return (
    <section className="home__advantages">
      <div className="home__advantages__content">
        <h2 className="home__advantages__title">
          {t("Broadcast your shows and privates on")}{" "}
          <a href="https://RichCams.com" target="_blank" rel="noreferrer">
            {t("RichCams.com")}
          </a>
        </h2>
        <div className="home__advantages__info">
          <div className="home__advantages__info__list">
            <h3>{t("Each of our MODELS / STUDIOS gets:")}</h3>
            <ul>
              {/* монтируем массив с преимуществами в код */}
              {advantagesList.map((el) => (
                <li key={el.title} className="home__advantages__info__list__li">
                  <span className="home__advantages__info__list__li__title">
                    <img src={el.img} alt="icon" />
                    <h4>{t(el.title)}</h4>
                  </span>

                  {el.description && (
                    <span className="home__advantages__info__list__li__description">
                      {t(el.description)}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="home__advantages__info__img">
            <img src={advantagesGirl} alt="girl" />
          </div>
        </div>
      </div>
    </section>
  );
}
