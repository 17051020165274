import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";
import { API_LOGIN, serverType } from "../../api/login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import { CAPTCHA, SITE_KEY } from "./consts";
import ReCAPTCHA from "react-google-recaptcha";

export default function NewPas({ type, close }) {
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);
  const passRef = useRef(null);
  const captchaRef = useRef(null);
  const LangToHl = { english: "EN", russian: "RU" };
  const HL = LangToHl[t("currentLang")] || "EN";
  const [query] = useSearchParams();
  const code = query.get("code");

  const onNewPas = async (e) => {
    e.preventDefault();

    window.history.pushState("", "", "/");
    setBtnLoading(true);
    API_LOGIN.password
      .reset(
        {
          code: code,
          password: passRef.current,
          type: serverType[type],
          captcha: captchaRef.current?.getValue(),
        },
        type
      )
      .then((e) => {
        if (!e.data) {
          toast(e.data.message || "Unexpected data received from the server", {
            style: { background: "#008000", color: "#fff" },
          });
        }

        if (e.data.status || e.data.ok) {
          toast(e.data.message, {
            style: { background: "#008000", color: "#fff" },
          });
          return;
        }

        toast(e.data.message || t("An unknown error was received"), {
          style: { background: "#f00", color: "#fff" },
        });

        console.log(e);
      })
      .catch((e) => {
        if (!e.response) {
          toast(t("An unknown error was received"), {
            style: { background: "#f00", color: "#fff" },
          });
        }

        toast(e.data.message || t("An unknown error was received"), {
          style: { background: "#f00", color: "#fff" },
        });
      })
      .finally(() => {
        setBtnLoading(false);
        captchaRef.current?.reset();
        close();
      });
  };

  return (
    <>
      <div className="reset-password">
        <h1 className="reset-password__title">{t("New password")}</h1>
        <form onSubmit={onNewPas} className="reset-password__content">
          <CusInput
            onChange={(e) => (passRef.current = e.target.value)}
            required
            dopOptions={{ minLength: 8 }}
            type={"password"}
            text={"New password"}
          />

          {!!CAPTCHA?.["protect-reset-password-page"] && (
            <ReCAPTCHA ref={captchaRef} hl={HL} sitekey={SITE_KEY} />
          )}

          <CusButton
            type="submit"
            loading={btnLoading}
            className="cus-button__outline"
            text={"submit"}
          />
          <p>{t("Enter your new password")}</p>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
