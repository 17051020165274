import React, { useRef, useState } from "react";
import imgStream from "../../images/broadcams/stream.svg";
import imgTg from "../../images/icons/telegram.svg";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";
import imgMail from "../../images/icons/mail.svg";
import imgLock from "../../images/icons/lock-fill.svg";
import { CAPTCHA, MODEL, SITE_KEY, STUDIO } from "../common/consts";
import ReCAPTCHA from "react-google-recaptcha";
import CusRadio from "../mui/CusRadio";
import ApproveModal from "./ApproveModal";
import { useTranslation } from "react-i18next";
import { getFormInputs, redirectToLk } from "../../utils/utils";
import { API_LOGIN } from "../../api/login";
import Policy from "./policy/Policy";

const termsOfService = `Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.
  Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.
  Company's Websites: Website(s) owned, operated, or controlled by the Company or its subsidiaries, including the White Label Websites developed, maintained, or hosted by the Company or its subsidiaries.
  White Label Website is a website developed, maintained and/or hosted by the Company being its promotional tool used by the Affiliate for further advertising, marketing and/or promotion of the live webcam site(s) operated by the Company. White Label Website is offered as is and the Affiliate is obliged to accept it on an "as is" basis.
  Program: All marketing and promotional goals established by the Company as well as its subsidiaries and partners, and to their respective set of rules, benefits, revenues, and corresponding obligations arising from the execution of the present Agreement, namely pertaining to the use of marketing tools offered on the Company's Websites, including the White Label Websites, and in compliance with rules and regulations inherent to their use, as well as any means used to promote the Company's Websites, the Affiliate being bound by the principles and provisions set forth in the present Agreement.
  Program Participant: Any Affiliate participating in the Program under this Agreement.`;

const approveTexts = {
  service: { title: "Terms of service", text: termsOfService },
  privacy: { title: "Privacy polices", text: <Policy /> },
};

export default function Broadcast({ queryObj }) {
  // какой тип модалки открыть в зависимости от клика

  const [openTypeApprove, setOpenTypeApprove] = useState(false);
  const [errorMes, setErrorMes] = useState("");
  const [successMes, setSuccessMes] = useState("");
  const [disabled, setDisabled] = useState(false);

  const titleModal = approveTexts[openTypeApprove]?.title;
  const textModal = approveTexts[openTypeApprove]?.text;

  const captchaRef = useRef(null);

  // const formFieldRef = useRef();

  const { t } = useTranslation();

  // registerOptions - массив со значениями для радио групп вида
  // [{
  //   title - заголовок который будет виден пользователю ,
  //   value - значение которое  передастся в html,
  //   info  - информация которая будет выведена в попапе при наведении на кружочек ,
  // }]
  const registerOptions = [
    {
      title: "Solo model / broadcaster",
      value: "broadcaster",
      info: "For broadcasting on a streaming platform RichCams.com",
    },
    {
      title: "Studio",
      value: "manager",
      info: "to manage your models / streamers",
    },
  ];

  const onFormSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const children = e.target.querySelectorAll("input");
    const data = getFormInputs(children);
    data.login_after_join = true;
    data.captcha = captchaRef.current?.getValue();

    const type = data.type === "broadcaster" ? MODEL : STUDIO;

    if (queryObj["campaign_id"]) {
      data.campaign_id = +queryObj["campaign_id"];
      data.options = queryObj;
    }

    setErrorMes("");
    setSuccessMes("");

    API_LOGIN.register(data, type)
      .then((e) => {
        console.log(e);

        if (!e.data) setErrorMes(t(e.data.message || "Unexpected data received from the server"));

        if (e.data.status || e.data.ok) {
          // setSuccessMes(t("a confirmation email has been sent"));
          setSuccessMes(t(e.data.message || "a confirmation email has been sent"));
          if (type) redirectToLk(type);
          return;
        }

        setErrorMes(e.data.message || t("An unknown error was received"));
      })
      .catch((e) => {
        console.log(e);

        if (!e.response) setErrorMes(t("An unknown error was received"));
        setErrorMes(e.response.data.message || t("An unknown error was received"));
      })
      .finally(() => {
        setDisabled(false);
        captchaRef.current?.reset();
      });
  };

  return (
    <>
      <section className="home__broadcast">
        <div className="home__broadcast__content">
          <div className={`home__broadcast__info `}>
            {/* home-broadcast__text-hello */}
            <div className="home__broadcast__text-hello">
              <span>{t("Broadcast on")}</span>
              <h1>
                <a href="https://RichCams.com" target="_blank" rel="noreferrer">
                  {t("RichCams.com")}
                </a>
              </h1>
              <span>{t("and make more money")}</span>
            </div>
            {/* home-broadcast__text-percent */}
            <div className="home__broadcast__text-percent">
              <h2 className="home__broadcast__text__accent">50%</h2>
              <span className="home__broadcast__text__terms">
                {t("from")}
                <span className="home__broadcast__text__accent"> {t("OUR")}</span> {t("customers")}
              </span>
              <br />
              <span className="home__broadcast__text__remark">
                {t("Get 50% of all spending by our website visitors")}
              </span>
            </div>
            {/* home-broadcast__terms */}
            <div className="home__broadcast__terms">
              <div className="home__broadcast__terms__100">
                <h3 className="home__broadcast__text__accent">100%</h3>
                <span className="home__broadcast__terms__text">
                  {t("from")}
                  <span className="home__broadcast__text__accent"> {t("YOUR")}</span>{" "}
                  {t("visitors")}
                </span>
                <br />
                <span className="home__broadcast__text__remark">
                  {t("Get 100% of all spending of the visitors you referred")}
                </span>
              </div>
              <div className="home__broadcast__terms__100">
                <h3 className="home__broadcast__text__accent">10%</h3>
                <span className="home__broadcast__text__terms"> {t("from streamer models")} </span>
                <br />
                <span className="home__broadcast__text__remark">
                  {t("Refer broadcasters and earn 10% of all their earnings")}
                </span>
              </div>
              <div className="home__broadcast__terms__footer">
                <span className="home__broadcast__text__remark">
                  {t("Invite your clients and friends with your referral link to")}{" "}
                  <a href="https://RichCams.com" target="_blank" rel="noreferrer">
                    {t("RichCams.com")}
                  </a>
                </span>
                <p className="home__broadcast__terms__footer__accent-mini">
                  {t("Each of them will get 50 tokens")}
                </p>
              </div>
            </div>
          </div>

          <div className="home__broadcast__register">
            <span className="home__broadcast__register__title">
              <h2>
                <img src={imgStream} alt="imgStream" />
                {t("Create an account for streaming")}
              </h2>
              <span className="home__broadcast__register__subtitle">
                {t("by")}{" "}
                <a target="_blank" href="https://RichCams.com" rel="noreferrer">
                  {t("RichCams.com")}
                </a>
              </span>
            </span>

            <form onSubmit={onFormSubmit}>
              <CusInput
                onChange={() => {
                  setErrorMes("");
                  setSuccessMes("");
                }}
                name={"email"}
                icon={imgMail}
                type={"email"}
                required
                text={"E-mail*"}
                grayWarning={"This field is required"}
                className="home__broadcast__register__input"
              />

              <CusInput
                onChange={() => {
                  setErrorMes("");
                  setSuccessMes("");
                }}
                name={"password"}
                icon={imgLock}
                required
                text={"Password*"}
                grayWarning={"This field is required"}
                className="home__broadcast__register__input"
              />

              <CusInput
                onChange={() => {
                  setErrorMes("");
                  setSuccessMes("");
                }}
                name={"telegram"}
                icon={imgTg}
                text={"Telegram"}
                grayWarning={"if you have"}
                className="home__broadcast__register__input"
              />

              <CusRadio
                onChange={() => {
                  setErrorMes("");
                  setSuccessMes("");
                }}
                error={errorMes}
                name={"type"}
                options={registerOptions}
                defaultChecked={(indx) => indx === 0}
              />

              {!!CAPTCHA?.["protect-login-page"] && (
                <div className="recaptche-container">
                  <ReCAPTCHA ref={captchaRef} hl={"ru"} sitekey={SITE_KEY} />
                </div>
              )}

              {errorMes && <span className="error-text">{errorMes}</span>}
              {successMes && <span className="success-text">{successMes}</span>}

              <CusButton disabled={disabled} type="submit" text={"Sign up for free"} />
            </form>

            <span className="home__broadcast__register__text">
              <span className="home__broadcast__register__text__terms">
                {t("By signing up, you indicate your agreement with our")}{" "}
                <button onClick={() => setOpenTypeApprove("service")}> {t("Terms of Use")}</button>{" "}
                {t("and")}{" "}
                <button onClick={() => setOpenTypeApprove("privacy")}>{t("Privacy Policy")}</button>
              </span>
              <span className="home__broadcast__register__text__remark">
                {t(
                  "You understand that your personal data will be publicly available and used for the  provision of our services and our partner’s services"
                )}
              </span>
            </span>
          </div>
        </div>
      </section>

      <ApproveModal
        setOpen={setOpenTypeApprove}
        isOpen={!!openTypeApprove}
        title={titleModal}
        text={textModal}
        type={openTypeApprove}
      />
    </>
  );
}
