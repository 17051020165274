import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../components/mui/Spinner";
import { API_LOGIN } from "../api/login";
import { MODEL } from "../components/common/consts";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ConfirmModel() {
  const { t } = useTranslation();

  const [query] = useSearchParams();
  const key = query.get("key");
  const email = query.get("email");
  const navigate = useNavigate();

  useEffect(() => {
    const data = { key: key, email: email };

    API_LOGIN.confirm(data, MODEL)
      .then((e) => {
        console.log(e);
        navigate({
          pathname: "/",
          search: `confirmEmail=true&type=${MODEL}`,
        });
      })
      .catch((e) => {
        console.log(e);
        navigate({
          pathname: "/",
          search: `errorEmail=true&type=${MODEL}`,
        });
      });
  }, []);

  return (
    <main
      className={`home ${t("currentLang")}`}
      style={{
        display: "flex",
        minHeight: "100vh",
        background: "black",
      }}
    >
      <Spinner style={{ margin: "auto" }} />
    </main>
  );
}
