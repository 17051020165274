import axios from "axios";
import { API_URL_MODEL } from "./main";

export const setIssueClaim = async (data) => {
  const URL = `${API_URL_MODEL}/site/issue/claim`;

  let response = await axios({
    method: "POST",
    url: URL,
    data: data,
  });
  return response;
};

export const API_INFO = { setIssueClaim };
