import React, { useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import CloseModal from "../mui/CloseModal";
import CusInput from "../mui/CusInput";
import CusButton from "../mui/CusButton";
import { CAPTCHA, LOGIN, MODEL, SIGN_UP, SITE_KEY } from "./consts";

import imgEmail from "../../images/icons/mail.svg";
import imgLock from "../../images/icons/lock-fill.svg";
import imgTg from "../../images/icons/telegram.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import ResetPas from "./ResetPas";
import { getFormInputs } from "../../utils/utils";
import { API_LOGIN, serverType } from "../../api/login";
import NewPas from "./NewPas";

export default function SignUpModal({ isOpen, setOpen, type = SIGN_UP, loginFor, resetPas }) {
  const LangToHl = { english: "EN", russian: "RU" };
  const title = type === SIGN_UP ? "Become a partner" : `Login as ${loginFor}`;
  const textButton = type === SIGN_UP ? "Create an account" : "Login";
  const { t } = useTranslation();

  const [isResetPas, setIsResetPas] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [errorMes, setErrorMes] = useState("");
  const captchaRef = useRef(null);

  const fields = useMemo(() => {
    const tgField = { text: "Telegram", icon: imgTg, type: "text", grayWarning: "if you have" };
    const GWPasText = "The minimum length is 8 characters";

    const fields = [
      {
        name: "email",
        text: "E-mail",
        icon: imgEmail,
        // type: "email",
        type: "text",
        grayWarning: "Email is required",
        required: true,
      },
      {
        name: "password",
        text: "Password",
        icon: imgLock,
        type: "password",
        grayWarning: GWPasText,
        dopOptions: { minLength: 8 },
        required: true,
      },
    ];

    if (type === SIGN_UP) fields.push(tgField);
    return fields;
  }, [type]);

  const HL = LangToHl[t("currentLang")] || "EN";

  const closeModal = () => {
    setIsResetPas(false);
    setOpen(false);
    setErrorMes("");
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const children = e.target.querySelectorAll("input");
    const data = getFormInputs(children);
    data.captcha = captchaRef.current?.getValue();
    data.type = serverType[loginFor];
    console.log(data);
    API_LOGIN.login(data, loginFor)
      .then((e) => {
        if (!e.data)
          setErrorMes(t(e.data.message || t("Unexpected data received from the server")));

        if (e.data.status || e.data.ok) {
          window.location.href =
            loginFor === MODEL ? "/webcam/model/chat" : "/webcam/studio/manager";
          return;
        }

        setErrorMes(e.data.message);
      })
      .catch((e) => {
        console.log(e);
        if (!e.response) setErrorMes(t("An unknown error was received"));
        setErrorMes(e.response.data.message || t("An unknown error was received"));
      })
      .finally(() => {
        setBtnLoading(false);
        captchaRef.current?.reset();
      });

    setBtnLoading(false);
  };

  if (resetPas) {
    return (
      <Modal
        ariaHideApp={false}
        className={`login-modal`}
        isOpen={isOpen}
        onRequestClose={() => console.log()}
      >
        <NewPas close={closeModal} type={loginFor} />
        <CloseModal close={closeModal} />
      </Modal>
    );
  }

  return (
    <Modal
      ariaHideApp={false}
      className={`login-modal`}
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <CloseModal close={closeModal} />

      {!isResetPas || type === SIGN_UP ? (
        <>
          <h1 className="login-modal__title">{t(title)}</h1>

          <form onSubmit={onFormSubmit} className="login-modal__form">
            {fields?.map((option, indx) => (
              <CusInput
                onChange={() => setErrorMes("")}
                name={option.name}
                key={indx}
                {...option}
              />
            ))}
            {!!CAPTCHA?.["protect-login-page"] && (
              <ReCAPTCHA ref={captchaRef} hl={HL} sitekey={SITE_KEY} />
            )}

            {type === LOGIN && (
              <button
                type="button"
                className="login-modal__reset-pas"
                onClick={() => setIsResetPas(true)}
              >
                {t("Reset Password")}
              </button>
            )}

            {errorMes && <span className="error-text">{errorMes}</span>}

            <CusButton
              type="submit"
              loading={btnLoading}
              text={textButton}
              className="login-modal__button"
            />
            {/* <button type="submit" /> */}
          </form>
        </>
      ) : (
        <ResetPas close={closeModal} type={loginFor} />
      )}
    </Modal>
  );
}
