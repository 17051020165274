import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

// CusButton - дефолтный компонент кнопки

// img - путь к картинки
//   text - текст
//   subText - надпись под тексом
//   className - имя класса ,
//   onClick - функция при клике,
//   loading - флаг показывать ли спиннер,
//   disabled - флаг доступна ли кнопка,
//   type = "button" - html тип кнопки,
export default function CusButton({
  img,
  text,
  subText,
  className = "",
  onClick,
  loading,
  disabled,
  type = "button",
}) {
  const { t } = useTranslation();
  const isLoading = loading ? "loading" : "";

  return (
    <button
      type={type}
      className={`custom-button ${isLoading} ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <>
        <span className="custom-button__content">
          <span className="custom-button__main-text">
            {!!img && <img src={img} alt="login"></img>}
            <span>{t(text)}</span>
          </span>
          {subText && <span className="custom-button__sub-text">{t(subText)}</span>}
        </span>
        {loading && <Spinner />}
      </>
    </button>
  );
}
