import React, { useState } from "react";
import imgEye from "../../images/icons/eye.svg";
import imgEyeOff from "../../images/icons/eye-off.svg";
import { useTranslation } from "react-i18next";

// CusInput - дефолтный компонент инпута
//  icon, - иконка в инпуте
//  text, - html пласхолдер инпута
//  type, - html тип инпута
//  required  - html атрибут обязателен ли инпут
//  grayWarning, - предупреждение под инпутом
//  dopOptions, - дополнительные html атрибуты инпута
//  className, - класс для инпута

export default function CusInput({
  text,
  type,
  icon,
  name,
  errorColor,
  grayWarning,
  dopOptions,
  required,
  onChange,
  ref,
  className = "",
}) {
  const { t } = useTranslation();
  const [showPas, setShowPas] = useState(false);
  const typeInput = type == "password" && showPas ? "text" : type;

  return (
    <div className={`custom-input ${className}`}>
      <img src={icon} alt="" />
      <input
        ref={ref}
        onChange={onChange}
        name={name}
        required={required}
        type={typeInput}
        placeholder={t(text)}
        className={`custom-input__input ${errorColor ? "error-input" : ""}`}
        {...dopOptions}
      />
      {grayWarning && <span className="custom-input__gray-warning">{t(grayWarning)}</span>}
      {type === "password" && (
        <button
          type="button"
          className="password__hide__btn"
          onClick={(e) => {
            e.preventDefault();
            setShowPas((prev) => !prev);
          }}
        >
          <img
            className="pas-icon"
            src={!showPas ? imgEye : imgEyeOff}
            alt={showPas ? "hide" : "show"}
          />
        </button>
      )}
    </div>
  );
}
