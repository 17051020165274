import React, { useEffect, useState } from "react";
import HomeHeader from "../components/home/HomeHeader";
import HomeFooter from "../components/home/HomeFooter";
import Broadcast from "../components/home/Broadcast";
import Advantages from "../components/home/Advantages";
import Income from "../components/home/Income";
import UpperFooter from "../components/home/UpperFooter";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import { IS_CONFIRM } from "../components/common/consts";
import { redirectToLk } from "../utils/utils";
export default function Home() {
  const { t } = useTranslation();

  const [query] = useSearchParams();
  const successToast = query.get("confirmEmail");
  const errorToast = query.get("errorEmail");
  const type = query.get("type");
  const [isConfirm, setIsConfirm] = useState(IS_CONFIRM);

  const queryObj = {};
  query.forEach((el, key) => {
    queryObj[key] = el;
  });

  useEffect(() => {
    console.log(window.startConfig);

    if (successToast) {
      toast(t("your email has been successfully confirmed"), {
        style: { background: "#008000", color: "#fff" },
      });
      setIsConfirm(true);
      window.history.pushState("", "", "/");

      if (type) redirectToLk(type);

      return;
    }

    if (errorToast) {
      toast("An error has occurred", { style: { background: "#f00", color: "#fff" } });
      window.history.pushState("", "", "/");

      return;
    }
  }, []);

  return (
    <>
      <main className={`home ${t("currentLang")}`}>
        <HomeHeader isConfirm={isConfirm} />
        <Broadcast queryObj={queryObj} />
        <Advantages />
        <Income />
        <UpperFooter />
        <HomeFooter />
      </main>
      <ToastContainer />
    </>
  );
}
