import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import CusInput from "../../mui/CusInput";
import ReCAPTCHA from "react-google-recaptcha";
import CusButton from "../../mui/CusButton";
import { SITE_KEY } from "../../common/consts";
import CusForm from "../../mui/CusForm";
import CusTextArea from "../../mui/CusTextArea";
import "./form-modal.scss";
import CusRadio from "../../mui/CusRadio";
import { API_INFO } from "../../../api/info";
import { toast } from "react-toastify";

export default function FormContent({ name }) {
  const { t } = useTranslation();
  const LangToHl = { english: "EN", russian: "RU" };
  const HL = LangToHl[t("currentLang")] || "EN";

  const captchaRef = useRef();

  const radioOptions = [
    { title: "Yes", value: 1 },
    { title: "No", value: 0 },
  ];

  const onError = () => {
    captchaRef.current?.reset();
  };

  const onSubmit = (_, fields = {}) => {
    const data = fields;
    delete data["g-recaptcha-response"];

    data["distribution-agreed"] = !!fields["distribution-agreed"];
    data.captcha = captchaRef.current?.getValue();

    const errorToast = (e) => {
      const mes = e || t("An error has occurred");
      toast(mes, {
        style: { background: "#f00", color: "#fff" },
      });
    };

    API_INFO.setIssueClaim(data)
      .then((e) => {
        console.log("e", e);
        if (!e?.data?.ok) return errorToast(e);

        const mes = e?.data?.message || t("Successfully sent");

        toast(mes);
      })
      .catch(errorToast)
      .finally(() => captchaRef.current?.reset());
  };

  return (
    <div className={`form-modal ${name}`}>
      <p>
        {t(
          "If you have issue with or have been depicted improperly in content and you would like to appeal for its removal, please complete and submit a content removal/complaint form with the following:"
        )}
      </p>

      <CusForm className={"form-modal__content"} onSubmit={onSubmit} onError={onError}>
        <CusInput name={"url"} text={"URL of Content you are Reporting"} required />
        <div className="form-modal__two">
          <div className="form-modal__two__content">
            <CusInput name={"firstname"} text={"First Name"} required />
            <CusInput name={"lastname"} text={"Last Name"} required />
          </div>
        </div>

        <CusInput
          text={"You Email Address"}
          name={"email"}
          dataType={"email"}
          type={"email"}
          required
          placeholder={"Please enter your email address here"}
        />
        <CusTextArea
          text={"More information"}
          name={"desc"}
          required
          placeholder={"Please includes the website/merchant and user in question"}
        />
        <div className="form-modal__fieldset__container">
          <h2>
            {t("Have you agreed to the distributions of this content?")}
            <span className="required"> *</span>
          </h2>
          <CusRadio required name={"distribution-agreed"} options={radioOptions} />
        </div>
        {SITE_KEY && <ReCAPTCHA hl={HL} sitekey={SITE_KEY} ref={captchaRef} />}

        <CusButton text={"Send"} className="form-modal__button" fullWidth type="submit" />
      </CusForm>
    </div>
  );
}
