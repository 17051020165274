import React, { useState } from "react";
import CusButton from "../mui/CusButton";
import ChoseLang from "../mui/ChoseLang";

import imgModel from "../../images/icons/solid_women.svg";
import imgVideo from "../../images/icons/gridicons_video.svg";
import SignUpModal from "../common/SignUpModal";
import { LOGIN } from "../common/consts";
import { useTranslation } from "react-i18next";
import { API_LOGIN } from "../../api/login";
import logo from "../../images/logo/logo.svg";

/// HomeHeader - компонент шапки сайта
export default function HomeHeader({ isConfirm }) {
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const { t } = useTranslation();

  const logout = () => {
    API_LOGIN.logout().then((e) => {
      window.location.reload();
    });
  };
  const loginDashboard = () => {
    const studioType = window.startConfig?.user?.type === "manager";
    const url = studioType ? "/webcam/studio/manager" : "/webcam/model/chat";
    window.location.href = url;
  };

  return (
    <>
      <header className="header">
        <div className="header__content">
          <div className="header__text">
            {/* <h2 className="header__title">{t("Start.Stream")}</h2> */}
            <h2 className="header__title">
              <a href="https://richCams.com" target="_blank" rel="noreferrer">
                <img src={logo} alt="Start.Stream" />
              </a>
            </h2>
            <p className="header__subtitle">
              {t("Broadcast stream to")}{" "}
              <a href="https://richCams.com" target="_blank" rel="noreferrer">
                {t("RichCams.com")}
              </a>
            </p>
          </div>
          <div className="header__actions">
            {isConfirm ? (
              <div className="header__actions__buttons">
                <CusButton
                  onClick={loginDashboard}
                  className="custom-button__red"
                  text="Dashboard"
                />
                <CusButton text="Logout" onClick={logout} />
              </div>
            ) : (
              <div className="header__actions__buttons">
                <CusButton
                  onClick={(e) => setOpenLoginModal("Model")}
                  className="custom-button__red"
                  img={imgModel}
                  text="Login as Model"
                  subText={"Start streaming and make money"}
                />
                <CusButton
                  img={imgVideo}
                  text="Login as Studio"
                  subText={"Manage your models"}
                  onClick={(e) => setOpenLoginModal("Studio")}
                />
              </div>
            )}

            <ChoseLang />
          </div>
        </div>
      </header>

      <SignUpModal
        isOpen={!!openLoginModal}
        loginFor={openLoginModal}
        type={LOGIN}
        setOpen={setOpenLoginModal}
      />
    </>
  );
}
