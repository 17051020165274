import React from "react";
import "../cookie/cookie.scss";
import TermsContentEn from "./TermsContentEn";

export default function Terms() {
  return (
    <section className="cookie">
      <TermsContentEn />
    </section>
  );
}
