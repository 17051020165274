import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./style/style.sass";
import Home from "./pages/Home";
import ConfirmModel from "./pages/ConfirmModel";
import ConfirmStudio from "./pages/ConfirmStudio";
import {
  URL_CHANGE_PAS,
  URL_CONFIRM_MODEL,
  URL_CONFIRM_STUDIO,
  URL_NEW_PAS_MODEL,
  URL_NEW_PAS_STUDIO,
} from "./components/common/consts";
import ResetModel from "./pages/ResetModel";
import ResetStudio from "./pages/ResetStudio";
import useCommonHooks from "./hooks/useCommonHooks.js";
import EmailChange from "./pages/EmailChange.jsx";

function App() {
  useCommonHooks();

  return (
    <div className="App">
      <Router basename="">
        <Routes>
          <Route path={URL_CONFIRM_MODEL} element={<ConfirmModel />}></Route>
          <Route path={URL_CONFIRM_STUDIO} element={<ConfirmStudio />}></Route>
          <Route path={URL_NEW_PAS_STUDIO} element={<ResetStudio />}></Route>
          <Route path={URL_NEW_PAS_MODEL} element={<ResetModel />}></Route>
          <Route path={URL_CHANGE_PAS} element={<EmailChange />}></Route>
          <Route path="/*" element={<Home />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
